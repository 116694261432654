<template>
  <b-card title="CRS score" sub-title="Information about CRS score">
    <b-row>
      <b-col md="6">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Date of birth"
              label-for="date_of_birth"
              label-cols-md="4"
            >
              <b-input-group>
                <b-form-input
                  id="date_of_birth"
                  ref="date_of_birth"
                  v-model="patientInfo.date_of_birth"
                  type="text"
                  placeholder="DD.MM.YYYY"
                  v-mask="dateMask"
                  autocomplete="off"
                  show-decade-nav
                  :readonly="readOnly"
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="date1"
                    value-as-date
                    show-decade-nav
                    button-only
                    button-variant="outline-primary"
                    right
                    size="sm"
                    locale="de"
                    aria-controls="example-input"
                    :disabled="readOnly"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Procedure date"
              label-for="procedure_date"
              label-cols-md="4"
            >
              <b-input-group>
                <b-form-input
                  id="procedure_date"
                  ref="procedure_date"
                  v-model="patientInfo.procedure_date"
                  type="text"
                  placeholder="DD.MM.YYYY"
                  v-mask="dateMask"
                  autocomplete="off"
                  show-decade-nav
                  :readonly="readOnly"
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="date2"
                    value-as-date
                    show-decade-nav
                    button-only
                    button-variant="outline-primary"
                    right
                    size="sm"
                    locale="de"
                    aria-controls="example-input"
                    :disabled="readOnly"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Age at surgery [years]"
              label-for="age_at_surgery"
              label-cols-md="4"
            >
              <div class="vertical-align">
                {{ patientInfo.age_at_surgery }}
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Gender" label-for="gender" label-cols-md="4">
              <v-select
                label="value"
                :options="genders"
                v-model="patientInfo.gender"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="NYHA"
              label-for="status_preop_clinical_nyha"
              label-cols-md="4"
            >
              <v-select
                label="value"
                :options="options1"
                v-model="patientInfo.status_preop_clinical_nyha"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="COPD gold stage"
              label-for="status_preop_copd_gold_stage"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.status_preop_copd_gold_stage"
                label="value"
                :options="options2"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Stroke"
              label-for="status_preop_stroke"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.status_preop_stroke"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Dialysis"
              label-for="status_preop_dialysis"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.status_preop_dialysis"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Previous cardiac surgery"
              label-for="previous_cardiac_surgery"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.previous_cardiac_surgery"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Urgency"
              label-for="status_preop_urgency"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.status_preop_urgency"
                label="value"
                :options="options3"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col cols="12">
            <b-card class="bordered-card" title="CRS mortality">
              <b-row>
                <b-col cols="12">
                  <b-button
                    variant="outline-secondary"
                    block
                    v-ripple.400="'rgba(100, 100, 100, 0.15)'"
                    @click="calcMortality"
                  >
                    Calculate
                  </b-button>
                </b-col>
                <b-col cols="12" class="mt-1">
                  <b-form-group
                    label="Probability [%]:"
                    label-for="probability_mortality"
                    label-cols-md="4"
                  >
                    <div class="vertical-align">
                      {{ probability_mortality | percent }}
                    </div>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Score:"
                    label-for="risk_scores_preop_CRS_mortality_score_calculated"
                    label-cols-md="4"
                  >
                    <div class="vertical-align">
                      {{
                        this.patientInfo
                          .risk_scores_preop_CRS_mortality_score_calculated
                      }}
                    </div>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Score"
                    label-for="risk_scores_preop_CRS_mortality_score"
                    label-cols-md="4"
                  >
                    <b-form-input
                      v-model="
                        patientInfo.risk_scores_preop_CRS_mortality_score
                      "
                      id="risk_scores_preop_CRS_mortality_score"
                      type="number"
                      placeholder="Score"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card class="bordered-card" title="CRS morbidity">
              <b-row>
                <b-col cols="12">
                  <b-button
                    variant="outline-secondary"
                    block
                    v-ripple.400="'rgba(100, 100, 100, 0.15)'"
                    @click="calcMorbidity"
                  >
                    Calculate
                  </b-button>
                </b-col>
                <b-col cols="12" class="mt-1">
                  <b-form-group
                    label="Probability [0-1]:"
                    label-for="probability_morbidity"
                    label-cols-md="4"
                  >
                    <div class="vertical-align">
                      {{ probability_morbidity | percent }}
                    </div>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Score:"
                    label-for="risk_scores_preop_CRS_morbidity_score_calculated"
                    label-cols-md="4"
                  >
                    <div class="vertical-align">
                      {{
                        this.patientInfo
                          .risk_scores_preop_CRS_morbidity_score_calculated
                      }}
                    </div>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Score"
                    label-for="risk_scores_preop_CRS_morbidity_score"
                    label-cols-md="4"
                  >
                    <b-form-input
                      v-model="
                        patientInfo.risk_scores_preop_CRS_morbidity_score
                      "
                      id="risk_scores_preop_CRS_morbidity_score"
                      type="number"
                      placeholder="Score"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {
  convertDate2Str,
  dateMask,
  getCRS,
  getCRSProbabiliity,
  subtractDate,
} from "@core/utils/utils";
import { mapState } from "vuex";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      patientInfo: {
        _id: null,
        date_of_birth: "",
        procedure_date: "",
        age_at_surgery: "",
        gender: "",
        status_preop_clinical_nyha: "",
        status_preop_copd_gold_stage: "",
        status_preop_stroke: "",
        status_preop_dialysis: "",
        previous_cardiac_surgery: "",
        status_preop_urgency: "",
        risk_scores_preop_CRS_mortality_score: "",
        risk_scores_preop_CRS_morbidity_score: "",
      },
      probability_mortality: "",
      probability_morbidity: "",
      options: ["yes", "no"],
      options1: ["I", "II", "III", "IV"],
      options2: [
        "I FEV1 % vom Soll: ≥ 80%",
        "II FEV1 % vom Soll: 50-79%",
        "III FEV1 % vom Soll: 30-49%",
        "IV FEV1 % vom Soll: <30 %",
      ],
      options3: ["elective", "urgent", "emergent", "salvage"],
      genders: ["male", "female"],
      date1: null,
      date2: null,
    };
  },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
    age_at_surgery() {
      return subtractDate(
        this.patientInfo.date_of_birth,
        this.patientInfo.procedure_date,
        "year",
        true
      );
    },
  },
  watch: {
    date1(v, ov) {
      if (v) {
        this.patientInfo.date_of_birth = convertDate2Str(v);
      }
    },
    date2(v, ov) {
      if (v) {
        this.patientInfo.procedure_date = convertDate2Str(v);
      }
    },
    patient(v, ov) {
      if (v._id) {
        this.setPatientInfo(v);
      }
    },
    age_at_surgery(v, ov) {
      this.patientInfo.age_at_surgery = v;
    },
    hospital_stay(v, ov) {
      this.patientInfo.hospital_stay = v;
    },
  },
  mounted() {
    if (this.patient._id) {
      this.setPatientInfo(this.patient);
    }
  },
  methods: {
    dateMask,
    setPatientInfo(v) {
      this.patientInfo._id = v._id;

      if (document.activeElement != this.$refs.date_of_birth.$el) {
        this.patientInfo.date_of_birth = v.date_of_birth;
      }

      if (document.activeElement != this.$refs.procedure_date.$el) {
        this.patientInfo.procedure_date = v.procedure_date;
      }

      this.patientInfo.age_at_surgery = v.age_at_surgery;
      this.patientInfo.gender = v.gender;
      this.patientInfo.status_preop_clinical_nyha =
        v.status_preop_clinical_nyha;
      this.patientInfo.status_preop_copd_gold_stage =
        v.status_preop_copd_gold_stage;
      this.patientInfo.status_preop_stroke = v.status_preop_stroke;
      this.patientInfo.status_preop_dialysis = v.status_preop_dialysis;
      this.patientInfo.previous_cardiac_surgery = v.previous_cardiac_surgery;
      this.patientInfo.status_preop_urgency = v.status_preop_urgency;
      this.patientInfo.risk_scores_preop_CRS_mortality_score =
        v.risk_scores_preop_CRS_mortality_score;
      this.patientInfo.risk_scores_preop_CRS_mortality_score_calculated =
        v.risk_scores_preop_CRS_mortality_score_calculated;
      this.patientInfo.risk_scores_preop_CRS_morbidity_score =
        v.risk_scores_preop_CRS_morbidity_score;
      this.patientInfo.risk_scores_preop_CRS_morbidity_score_calculated =
        v.risk_scores_preop_CRS_morbidity_score_calculated;
    },
    calcMortality() {
      let score = getCRS(this.patient, "mortality");
      let prob = getCRSProbabiliity(score, "mortality");
      this.probability_mortality = prob;
      this.patientInfo.risk_scores_preop_CRS_mortality_score_calculated = score;
    },
    calcMorbidity() {
      let score = getCRS(this.patient, "morbidity");
      let prob = getCRSProbabiliity(score, "morbidity");
      this.probability_morbidity = prob;
      this.patientInfo.risk_scores_preop_CRS_morbidity_score_calculated = score;
    },
  },
};
</script>

<style>
</style>
