<template>
  <b-card title="TriSCORE" sub-title="Information about TriSCORE">
    <b-row>
      <b-col md="6">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Date of birth"
              label-for="date_of_birth"
              label-cols-md="4"
            >
              <b-input-group>
                <b-form-input
                  id="date_of_birth"
                  ref="date_of_birth"
                  v-model="patientInfo.date_of_birth"
                  type="text"
                  placeholder="DD.MM.YYYY"
                  v-mask="dateMask"
                  autocomplete="off"
                  show-decade-nav
                  :readonly="readOnly"
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="date1"
                    value-as-date
                    show-decade-nav
                    button-only
                    button-variant="outline-primary"
                    right
                    size="sm"
                    locale="de"
                    aria-controls="example-input"
                    :disabled="readOnly"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Procedure date"
              label-for="procedure_date"
              label-cols-md="4"
            >
              <b-input-group>
                <b-form-input
                  id="procedure_date"
                  ref="procedure_date"
                  v-model="patientInfo.procedure_date"
                  type="text"
                  placeholder="DD.MM.YYYY"
                  v-mask="dateMask"
                  autocomplete="off"
                  show-decade-nav
                  :readonly="readOnly"
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="date2"
                    value-as-date
                    show-decade-nav
                    button-only
                    button-variant="outline-primary"
                    right
                    size="sm"
                    locale="de"
                    aria-controls="example-input"
                    :disabled="readOnly"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Age at surgery [years]"
              label-for="age_at_surgery"
              label-cols-md="4"
            >
              <div class="vertical-align">
                {{ patientInfo.age_at_surgery }}
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="NYHA"
              label-for="status_preop_clinical_nyha"
              label-cols-md="4"
            >
              <v-select
                label="value"
                :options="options1"
                v-model="patientInfo.status_preop_clinical_nyha"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Right heart failure"
              label-for="right_heart_failure"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.status_preop_right_heart_failure"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Daily dose furosemid > 125mg"
              label-for="risk_scores_preop_TriSCORE_daily_dose_furosemid_gt_125mg"
              label-cols-md="4"
            >
              <v-select
                v-model="
                  patientInfo.risk_scores_preop_TriSCORE_daily_dose_furosemid_gt_125mg
                "
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Renal failure grade"
              label-for="renal_failure"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.status_preop_renal_failure_grade"
                label="value"
                :options="options6"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Bilirubin"
              label-for="bilirubin"
              label-cols-md="4"
            >
              <b-form-input
                v-model="patientInfo.preop_lab_bilirubin"
                id="bilirubin"
                type="number"
                placeholder="Bilirubin"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="LVEF" label-for="lvef" label-cols-md="4">
              <b-form-input
                v-model="patientInfo.preop_echo_LVEF"
                id="lvef"
                type="number"
                placeholder="LVEF"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Moderate/severe RV dysfunction"
              label-for="risk_scores_preop_TriSCORE_moderate_severe_RV_dysfunction"
              label-cols-md="4"
            >
              <v-select
                v-model="
                  patientInfo.risk_scores_preop_TriSCORE_moderate_severe_RV_dysfunction
                "
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col cols="12">
            <b-card class="bordered-card" title="TriSCORE mortality">
              <b-row>
                <b-col cols="12">
                  <b-button
                    variant="outline-secondary"
                    block
                    v-ripple.400="'rgba(100, 100, 100, 0.15)'"
                    @click="calcTriSCORE"
                  >
                    Calculate
                  </b-button>
                </b-col>
                <b-col cols="12" class="mt-1">
                  <b-form-group
                    label="Probability [%]:"
                    label-for="probability_mortality"
                    label-cols-md="4"
                  >
                    <div class="vertical-align">
                      {{ probability_mortality | percent }}
                    </div>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Score:"
                    label-for="risk_scores_preop_TriSCORE_calculated"
                    label-cols-md="4"
                  >
                    <div class="vertical-align">
                      {{
                        this.patientInfo.risk_scores_preop_TriSCORE_calculated
                      }}
                    </div>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Score"
                    label-for="risk_scores_preop_TriSCORE"
                    label-cols-md="4"
                  >
                    <b-form-input
                      v-model="patientInfo.risk_scores_preop_TriSCORE"
                      id="risk_scores_preop_TriSCORE"
                      type="number"
                      placeholder="Score"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {
  convertDate2Str,
  dateMask,
  getTriSCORE,
  subtractDate,
  GetTriSCOREProbability,
} from "@core/utils/utils";
import { mapState } from "vuex";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      patientInfo: {
        _id: null,
        date_of_birth: "",
        procedure_date: "",
        age_at_surgery: "",
        status_preop_clinical_nyha: "",
        status_preop_right_heart_failure: "",
        risk_scores_preop_TriSCORE_daily_dose_furosemid_gt_125mg: "",
        status_preop_renal_failure_grade: "",
        preop_lab_bilirubin: "",
        preop_echo_LVEF: "",
        risk_scores_preop_TriSCORE_moderate_severe_RV_dysfunction: "",
        risk_scores_preop_TriSCORE: "",
      },
      probability_mortality: "",
      options: ["yes", "no"],
      options1: ["I", "II", "III", "IV"],
      options2: [
        "I FEV1 % vom Soll: ≥ 80%",
        "II FEV1 % vom Soll: 50-79%",
        "III FEV1 % vom Soll: 30-49%",
        "IV FEV1 % vom Soll: <30 %",
      ],
      options3: ["elective", "urgent", "emergent", "salvage"],
      options6: [
        "normal GFR<85",
        "severe GFR<50",
        "moderate GFR 50-85",
        "on dialysis",
      ],
      genders: ["male", "female"],
      date1: null,
      date2: null,
    };
  },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
    age_at_surgery() {
      return subtractDate(
        this.patientInfo.date_of_birth,
        this.patientInfo.procedure_date,
        "year",
        true
      );
    },
  },
  watch: {
    date1(v, ov) {
      if (v) {
        this.patientInfo.date_of_birth = convertDate2Str(v);
      }
    },
    date2(v, ov) {
      if (v) {
        this.patientInfo.procedure_date = convertDate2Str(v);
      }
    },
    patient(v, ov) {
      if (v._id) {
        this.setPatientInfo(v);
      }
    },
    age_at_surgery(v, ov) {
      this.patientInfo.age_at_surgery = v;
    },
    hospital_stay(v, ov) {
      this.patientInfo.hospital_stay = v;
    },
  },
  mounted() {
    if (this.patient._id) {
      this.setPatientInfo(this.patient);
    }
  },
  methods: {
    dateMask,
    setPatientInfo(v) {
      this.patientInfo._id = v._id;

      if (document.activeElement != this.$refs.date_of_birth.$el) {
        this.patientInfo.date_of_birth = v.date_of_birth;
      }

      if (document.activeElement != this.$refs.procedure_date.$el) {
        this.patientInfo.procedure_date = v.procedure_date;
      }

      this.patientInfo.age_at_surgery = v.age_at_surgery;
      this.patientInfo.status_preop_clinical_nyha =
        v.status_preop_clinical_nyha;
      this.patientInfo.status_preop_right_heart_failure =
        v.status_preop_right_heart_failure;
      this.patientInfo.risk_scores_preop_TriSCORE_daily_dose_furosemid_gt_125mg =
        v.risk_scores_preop_TriSCORE_daily_dose_furosemid_gt_125mg;
      this.patientInfo.status_preop_renal_failure_grade =
        v.status_preop_renal_failure_grade;
      this.patientInfo.preop_lab_bilirubin = v.preop_lab_bilirubin;
      this.patientInfo.preop_echo_LVEF = v.preop_echo_LVEF;
      this.patientInfo.risk_scores_preop_TriSCORE_moderate_severe_RV_dysfunction =
        v.risk_scores_preop_TriSCORE_moderate_severe_RV_dysfunction;
      this.patientInfo.risk_scores_preop_TriSCORE =
        v.risk_scores_preop_TriSCORE;
      this.patientInfo.risk_scores_preop_TriSCORE_calculated =
        v.risk_scores_preop_TriSCORE_calculated;
    },
    calcTriSCORE() {
      let score = getTriSCORE(this.patient, "score");
      let prob = GetTriSCOREProbability(score);

      this.probability_mortality = prob;
      this.patientInfo.risk_scores_preop_TriSCORE_calculated = score;
    },
  },
};
</script>

<style>
</style>
