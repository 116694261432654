<template>
    <b-card title="Procedure" sub-title="Information about procedure">
      <b-row>
        <b-col cols="12">
          <b-form-group
              label="Date"
              label-for="procedure_date"
              label-cols-md="4"
          >
            <b-input-group>
              <b-form-input
                  id="procedure_date"
                  ref="procedure_date"
                  v-model="patientInfo.procedure_date"
                  type="text"
                  placeholder="DD.MM.YYYY"
                  v-mask="dateMask"
                  autocomplete="off"
                  show-decade-nav
                  :readonly="readOnly"
              />
              <b-input-group-append>
                <b-form-datepicker
                    v-model="date1"
                    value-as-date
                    show-decade-nav
                    button-only
                    button-variant="outline-primary"
                    right
                    size="sm"
                    locale="de"
                    aria-controls="example-input"
                    :disabled="readOnly"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
              label="Admission"
              label-for="admission_date"
              label-cols-md="4"
          >
            <b-input-group>
              <b-form-input
                  id="admission_date"
                  ref="admission_date"
                  v-model="patientInfo.admission_date"
                  type="text"
                  placeholder="DD.MM.YYYY"
                  v-mask="dateMask"
                  autocomplete="off"
                  show-decade-nav
                  :readonly="readOnly"
              />
              <b-input-group-append>
                <b-form-datepicker
                    v-model="date2"
                    value-as-date
                    show-decade-nav
                    button-only
                    button-variant="outline-primary"
                    right
                    size="sm"
                    locale="de"
                    aria-controls="example-input"
                    :disabled="readOnly"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
              label="Discharge"
              label-for="discharge_date"
              label-cols-md="4"
          >
            <b-input-group>
              <b-form-input
                  id="discharge_date"
                  ref="discharge_date"
                  v-model="patientInfo.discharge_date"
                  type="text"
                  placeholder="DD.MM.YYYY"
                  v-mask="dateMask"
                  autocomplete="off"
                  show-decade-nav
                  :readonly="readOnly"
              />
              <b-input-group-append>
                <b-form-datepicker
                    v-model="date3"
                    value-as-date
                    show-decade-nav
                    button-only
                    button-variant="outline-primary"
                    right
                    size="sm"
                    locale="de"
                    aria-controls="example-input"
                    :disabled="readOnly"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
              label="Age at surgery [years]"
              label-for="age_at_surgery"
              label-cols-md="4"
          >
            <div class="vertical-align">
              {{ patientInfo.age_at_surgery }}
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
              label="Hospital stay [d]: "
              label-for="age_at_surgery"
              label-cols-md="4"
          >
            <div class="vertical-align">
              {{ patientInfo.hospital_stay }}
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-card class="bordered-card" title="Discharge">
            <b-row>
              <b-col cols="12">
                <b-form-group
                    label="Cardiovascular death"
                    label-for="discharge_cardiovascular_death"
                    label-cols-md="4"
                >
                    <v-select
                        label="value"
                        :options="options"
                        v-model="patientInfo.discharge_cardiovascular_death"
                        :disabled="readOnly"
                    />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                    label="In hospital death"
                    label-for="discharge_in_hospital_death"
                    label-cols-md="4"
                >
                    <v-select
                        label="value"
                        :options="options"
                        v-model="patientInfo.discharge_in_hospital_death"
                        :disabled="readOnly"
                    />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                    label="Date of death"
                    label-for="discharge_date_of_death"
                    label-cols-md="4"
                >
                  <b-input-group>
                    <b-form-input
                        id="discharge_date_of_death"
                        ref="discharge_date_of_death"
                        v-model="patientInfo.discharge_date_of_death"
                        type="text"
                        placeholder="DD.MM.YYYY"
                        v-mask="dateMask"
                        autocomplete="off"
                        show-decade-nav
                        :readonly="readOnly"
                    />
                    <b-input-group-append>
                      <b-form-datepicker
                          v-model="date4"
                          value-as-date
                          show-decade-nav
                          button-only
                          button-variant="outline-primary"
                          right
                          size="sm"
                          locale="de"
                          aria-controls="example-input"
                          :disabled="readOnly"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                    label="Survival days: "
                    label-for="survival_days"
                    label-cols-md="4"
                >
                  <div class="vertical-align">
                    {{ patientInfo.survival_days }}
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BFormDatepicker
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { convertDate2Str, dateMask, subtractDate } from '@core/utils/utils'
import { mapState } from 'vuex'

export default {
  components: {
    BRow, BCol, BCard, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BFormDatepicker,
    vSelect
  },
  data() {
    return {
      patientInfo: {
        _id: null,
        procedure_date: '',
        admission_date: '',
        discharge_date: '',
        age_at_surgery: '',
        hospital_stay: '',
        discharge_cardiovascular_death: '',
        discharge_in_hospital_death: '',
        discharge_date_of_death: '',
        survival_days: '',
      },
      value: '',
      options: ['yes', 'no'],
      date1: null,
      date2: null,
      date3: null,
      date4: null,
    }
  },
  computed: {
    ...mapState('patient', ['patient', 'role']),
    readOnly() {
      return this.role == 'viewer' || this.role == ''
    },
    age_at_surgery() {
      return subtractDate(this.patient.date_of_birth, this.patientInfo.procedure_date, 'year', true)
    },
    hospital_stay() {
      return subtractDate(this.patientInfo.admission_date, this.patientInfo.discharge_date, 'day')
    },
    survival_days() {
      return subtractDate(this.patient.follow_up_last_follow_up_date, this.patientInfo.discharge_date_of_death, 'day')
    }
  },
  watch: {
    date1(v, ov) {
      if (v) {
        this.patientInfo.procedure_date = convertDate2Str(v)
      }
    },
    date2(v, ov) {
      if (v) {
        this.patientInfo.admission_date = convertDate2Str(v)
      }
    },
    date3(v, ov) {
      if (v) {
        this.patientInfo.discharge_date = convertDate2Str(v)
      }
    },
    date4(v, ov) {
      if (v) {
        this.patientInfo.discharge_date_of_death = convertDate2Str(v)
      }
    },
		patient(v, ov) {
      if (v._id) {
        this.setPatientInfo(v)
      }
		},
    age_at_surgery(v, ov) {
      this.patientInfo.age_at_surgery = v
    },
    hospital_stay(v, ov) {
      this.patientInfo.hospital_stay = v
    },
    survival_days(v, ov) {
      this.patientInfo.survival_days = v
    }
  },
  mounted() {
    if (this.patient._id) {
      this.setPatientInfo(this.patient)
    }
  },
  methods: {
    dateMask,
    setPatientInfo(v) {
      this.patientInfo._id = v._id

      if (document.activeElement != this.$refs.procedure_date.$el) {
			  this.patientInfo.procedure_date = v.procedure_date
      }

      if (document.activeElement != this.$refs.admission_date.$el) {
			  this.patientInfo.admission_date = v.admission_date
      }

      if (document.activeElement != this.$refs.discharge_date.$el) {
			  this.patientInfo.discharge_date = v.discharge_date
      }

      this.patientInfo.age_at_surgery = v.age_at_surgery
      this.patientInfo.hospital_stay = v.hospital_stay
      this.patientInfo.discharge_cardiovascular_death = v.discharge_cardiovascular_death
      this.patientInfo.discharge_in_hospital_death = v.discharge_in_hospital_death

      if (document.activeElement != this.$refs.discharge_date_of_death.$el) {
			  this.patientInfo.discharge_date_of_death = v.discharge_date_of_death
      }

      this.patientInfo.survival_days = v.survival_days
    }
  }
}
</script>

<style>

</style>
