<template>
  <b-card title="EuroSCORE II" sub-title="Information about EuroSCORE II">
    <b-row>
      <b-col md="6">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="NYHA"
              label-for="status_preop_clinical_nyha"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.status_preop_clinical_nyha"
                label="value"
                :options="options1"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Unstable angina"
              label-for="risk_scores_preop_EuroSCORE_II_unstable_angina"
              label-cols-md="4"
            >
              <v-select
                v-model="
                  patientInfo.risk_scores_preop_EuroSCORE_II_unstable_angina
                "
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Diabetes"
              label-for="status_preop_diabetes"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.status_preop_diabetes"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Date of birth"
              label-for="date_of_birth"
              label-cols-md="4"
            >
              <b-input-group>
                <b-form-input
                  id="date_of_birth"
                  ref="date_of_birth"
                  v-model="patientInfo.date_of_birth"
                  type="text"
                  placeholder="DD.MM.YYYY"
                  v-mask="dateMask"
                  autocomplete="off"
                  show-decade-nav
                  :readonly="readOnly"
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="date1"
                    value-as-date
                    show-decade-nav
                    button-only
                    button-variant="outline-primary"
                    right
                    size="sm"
                    locale="de"
                    aria-controls="example-input"
                    :disabled="readOnly"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Procedure date"
              label-for="procedure_date"
              label-cols-md="4"
            >
              <b-input-group>
                <b-form-input
                  id="procedure_date"
                  ref="procedure_date"
                  v-model="patientInfo.procedure_date"
                  type="text"
                  placeholder="DD.MM.YYYY"
                  v-mask="dateMask"
                  autocomplete="off"
                  show-decade-nav
                  :readonly="readOnly"
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="date2"
                    value-as-date
                    show-decade-nav
                    button-only
                    button-variant="outline-primary"
                    right
                    size="sm"
                    locale="de"
                    aria-controls="example-input"
                    :disabled="readOnly"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Age at surgery [years]"
              label-for="age_at_surgery"
              label-cols-md="4"
            >
              <div class="vertical-align">
                {{ patientInfo.age_at_surgery }}
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Gender" label-for="gender" label-cols-md="4">
              <v-select
                v-model="patientInfo.gender"
                :options="genders"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Peripheral arterial disease"
              label-for="status_preop_peripheral_arterial_disease"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.status_preop_peripheral_arterial_disease"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="COPD" label-for="copd" label-cols-md="4">
              <v-select
                v-model="patientInfo.status_preop_copd"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Poor mobility"
              label-for="risk_scores_preop_EuroSCORE_II_poor_mobility"
              label-cols-md="4"
            >
              <v-select
                v-model="
                  patientInfo.risk_scores_preop_EuroSCORE_II_poor_mobility
                "
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Previous cardiac surgery"
              label-for="previous_cardiac_surgery"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.previous_cardiac_surgery"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Renal failure grade"
              label-for="renal_failure"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.status_preop_renal_failure_grade"
                label="value"
                :options="options6"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="TV endocarditis type"
              label-for="tv_disease_endocarditis_type"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.tv_disease_endocarditis_type"
                label="value"
                :options="options2"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="MV endocarditis type"
              label-for="type"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.mv_disease_endocarditis_type"
                label="value"
                :options="options2"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Critical preop state"
              label-for="risk_scores_preop_EuroSCORE_II_critical_preop_state"
              label-cols-md="4"
            >
              <v-select
                v-model="
                  patientInfo.risk_scores_preop_EuroSCORE_II_critical_preop_state
                "
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="LV function"
              label-for="risk_scores_preop_EuroSCORE_II_LV_function"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.risk_scores_preop_EuroSCORE_II_LV_function"
                label="value"
                :options="options4"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Recent MI"
              label-for="risk_scores_preop_EuroSCORE_II_recent_MI"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.risk_scores_preop_EuroSCORE_II_recent_MI"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col cols="12">
            <b-card class="bordered-card" title="PA pressure systolic">
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="TV"
                    label-for="preop_echo_tv_PA_pressure_systolic"
                    label-cols-md="4"
                  >
                    <b-form-input
                      v-model="patientInfo.preop_echo_tv_PA_pressure_systolic"
                      id="preop_echo_tv_PA_pressure_systolic"
                      type="number"
                      placeholder="TV"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Svan Ganz"
                    label-for="preop_echo_svan_ganz_PA_systolic_p"
                    label-cols-md="4"
                  >
                    <b-form-input
                      v-model="patientInfo.preop_echo_svan_ganz_PA_systolic_p"
                      id="preop_echo_svan_ganz_PA_systolic_p"
                      type="number"
                      placeholder="Svan Ganz"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="PA systolic pressure"
                    label-for="risk_scores_preop_EuroSCORE_II_PA_systolic_pressure"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="
                        patientInfo.risk_scores_preop_EuroSCORE_II_PA_systolic_pressure
                      "
                      label="value"
                      :options="options5"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Urgency"
              label-for="status_preop_urgency"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.status_preop_urgency"
                label="value"
                :options="options3"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-card class="bordered-card" title="Weight of procedure">
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="TV"
                    label-for="intraop_op_tv"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="patientInfo.intraop_op_tv"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="MV"
                    label-for="intraop_op_mv"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="patientInfo.intraop_op_mv"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Concomitant procedure"
                    label-for="intraop_concomitant_procedures"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="patientInfo.intraop_concomitant_procedures"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Surgery thoracic aorta"
              label-for="risk_scores_preop_EuroSCORE_II_surgery_thoraic_aorta"
              label-cols-md="4"
            >
              <v-select
                v-model="
                  patientInfo.risk_scores_preop_EuroSCORE_II_surgery_thoraic_aorta
                "
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-card class="bordered-card" title="EuroSCORE II mortality">
              <b-row>
                <b-col cols="12">
                  <b-button
                    variant="outline-secondary"
                    block
                    v-ripple.400="'rgba(100, 100, 100, 0.15)'"
                    @click="calcEuroSCORE"
                  >
                    Calculate
                  </b-button>
                </b-col>
                <b-col cols="12" class="mt-1">
                  <b-form-group
                    label="Probability [%]:"
                    label-for="probability_mortality"
                    label-cols-md="4"
                  >
                    <div class="vertical-align">
                      {{ probability_mortality | percent }}
                    </div>
                  </b-form-group>
                </b-col>
                <!-- <b-col cols="12">
                    <b-form-group
                        label="Score:"
                        label-for="risk_scores_preop_EuroSCORE_II"
                        label-cols-md="4"
                    >
                      <div class="vertical-align">
                        {{ this.patientInfo.risk_scores_preop_EuroSCORE_II }}
                      </div>
                    </b-form-group>
                  </b-col> -->
                <b-col cols="12">
                  <b-form-group
                    label="Score"
                    label-for="risk_scores_preop_EuroSCORE_II"
                    label-cols-md="4"
                  >
                    <b-form-input
                      v-model="patientInfo.risk_scores_preop_EuroSCORE_II"
                      id="risk_scores_preop_EuroSCORE_II"
                      type="number"
                      placeholder="Score"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {
  convertDate2Str,
  dateMask,
  subtractDate,
  GetEuroSCOREII,
  getEuroSCOREII,
} from "@core/utils/utils";
import { mapState } from "vuex";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      patientInfo: {
        _id: null,
        status_preop_clinical_nyha: "",
        risk_scores_preop_EuroSCORE_II_unstable_angina: "",
        status_preop_diabetes: "",
        date_of_birth: "",
        procedure_date: "",
        age_at_surgery: "",
        gender: "",
        status_preop_peripheral_arterial_disease: "",
        status_preop_copd: "",
        risk_scores_preop_EuroSCORE_II_poor_mobility: "",
        previous_cardiac_surgery: "",
        status_preop_renal_failure_grade: "",
        tv_disease_endocarditis_type: "",
        mv_disease_endocarditis_type: "",
        risk_scores_preop_EuroSCORE_II_critical_preop_state: "",
        risk_scores_preop_EuroSCORE_II_LV_function: "",
        risk_scores_preop_EuroSCORE_II_recent_MI: "",
        preop_echo_tv_PA_pressure_systolic: "",
        preop_echo_svan_ganz_PA_systolic_p: "",
        risk_scores_preop_EuroSCORE_II_PA_systolic_pressure: "",
        status_preop_urgency: "",
        intraop_op_tv: "",
        intraop_op_mv: "",
        intraop_concomitant_procedures: "",
        risk_scores_preop_EuroSCORE_II_surgery_thoraic_aorta: "",
        risk_scores_preop_EuroSCORE_II: "",
      },
      probability_mortality: "",
      options: ["yes", "no"],
      options1: ["I", "II", "III", "IV"],
      options2: ["active", "status post"],
      options3: ["elective", "urgent", "emergent", "salvage"],
      options4: ["good", "moderate", "poor", "very poor"],
      options5: ["31-55 mmHg", ">=55 mmHg"],
      options6: [
        "normal GFR<85",
        "severe GFR<50",
        "moderate GFR 50-85",
        "on dialysis",
      ],
      genders: ["male", "female"],
      date1: null,
      date2: null,
    };
  },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
    age_at_surgery() {
      return subtractDate(
        this.patientInfo.date_of_birth,
        this.patientInfo.procedure_date,
        "year",
        true
      );
    },
  },
  watch: {
    date1(v, ov) {
      if (v) {
        this.patientInfo.date_of_birth = convertDate2Str(v);
      }
    },
    date2(v, ov) {
      if (v) {
        this.patientInfo.procedure_date = convertDate2Str(v);
      }
    },
    patient(v, ov) {
      if (v._id) {
        this.setPatientInfo(v);
      }
    },
    age_at_surgery(v, ov) {
      this.patientInfo.age_at_surgery = v;
    },
    hospital_stay(v, ov) {
      this.patientInfo.hospital_stay = v;
    },
  },
  mounted() {
    if (this.patient._id) {
      this.setPatientInfo(this.patient);
    }
  },
  methods: {
    dateMask,
    setPatientInfo(v) {
      this.patientInfo._id = v._id;

      this.patientInfo.status_preop_clinical_nyha =
        v.status_preop_clinical_nyha;
      this.patientInfo.risk_scores_preop_EuroSCORE_II_unstable_angina =
        v.risk_scores_preop_EuroSCORE_II_unstable_angina;
      this.patientInfo.status_preop_diabetes = v.status_preop_diabetes;

      if (document.activeElement != this.$refs.date_of_birth.$el) {
        this.patientInfo.date_of_birth = v.date_of_birth;
      }

      if (document.activeElement != this.$refs.procedure_date.$el) {
        this.patientInfo.procedure_date = v.procedure_date;
      }

      this.patientInfo.age_at_surgery = v.age_at_surgery;
      this.patientInfo.gender = v.gender;
      this.patientInfo.status_preop_peripheral_arterial_disease =
        v.status_preop_peripheral_arterial_disease;
      this.patientInfo.status_preop_copd = v.status_preop_copd;
      this.patientInfo.risk_scores_preop_EuroSCORE_II_poor_mobility =
        v.risk_scores_preop_EuroSCORE_II_poor_mobility;
      this.patientInfo.previous_cardiac_surgery = v.previous_cardiac_surgery;
      this.patientInfo.status_preop_renal_failure_grade =
        v.status_preop_renal_failure_grade;
      this.patientInfo.tv_disease_endocarditis_type =
        v.tv_disease_endocarditis_type;
      this.patientInfo.mv_disease_endocarditis_type =
        v.mv_disease_endocarditis_type;
      this.patientInfo.risk_scores_preop_EuroSCORE_II_critical_preop_state =
        v.risk_scores_preop_EuroSCORE_II_critical_preop_state;
      this.patientInfo.risk_scores_preop_EuroSCORE_II_LV_function =
        v.risk_scores_preop_EuroSCORE_II_LV_function;
      this.patientInfo.risk_scores_preop_EuroSCORE_II_recent_MI =
        v.risk_scores_preop_EuroSCORE_II_recent_MI;
      this.patientInfo.preop_echo_tv_PA_pressure_systolic =
        v.preop_echo_tv_PA_pressure_systolic;
      this.patientInfo.preop_echo_svan_ganz_PA_systolic_p =
        v.preop_echo_svan_ganz_PA_systolic_p;
      this.patientInfo.risk_scores_preop_EuroSCORE_II_PA_systolic_pressure =
        v.risk_scores_preop_EuroSCORE_II_PA_systolic_pressure;
      this.patientInfo.status_preop_urgency = v.status_preop_urgency;
      this.patientInfo.intraop_op_tv = v.intraop_op_tv;
      this.patientInfo.intraop_op_mv = v.intraop_op_mv;
      this.patientInfo.intraop_concomitant_procedures =
        v.intraop_concomitant_procedures;
      this.patientInfo.risk_scores_preop_EuroSCORE_II_surgery_thoraic_aorta =
        v.risk_scores_preop_EuroSCORE_II_surgery_thoraic_aorta;
      this.patientInfo.risk_scores_preop_EuroSCORE_II =
        v.risk_scores_preop_EuroSCORE_II;
    },
    calcEuroSCORE() {
      let prob = getEuroSCOREII(this.patient);

      this.probability_mortality = prob;
    },
  },
};
</script>

<style>
</style>